.timeline-items{
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 80px;
}
.timeline-wrapper::-webkit-scrollbar{
    display: none;
}
.timeline-wrapper{
    animation: render 1.5s ease-in-out 1 forwards;
}
@keyframes render {
    from{
        height: 0;
    }
    to {
        height: 100%;
    }
}
.timeline-items::before{
    content: '';
    position: absolute;
    width: 5px;
    height: 100%;
    background-color: #47feec;
    left: calc(50% - 1px);
    border-radius: 10px;
}
.timeline-item{
    margin-bottom: 40px;
    width: 100%;
    position: relative;
}
.timeline-item:last-child{
    margin-bottom: 5vh;
}
.timeline-item:nth-child(odd){
    padding-right: calc(50% + 30px);
    text-align: right;
}
.timeline-item:nth-child(even){
    padding-left: calc(50% + 30px);
}
.timeline-dot{
    width: 21px;
    height: 21px;
    background-color: #47feec;
    box-shadow: 0 0 25px #47feec,
                0 0 25px #47feec;
    position: absolute;
    left: calc(50% - 8px);
    border-radius: 50%;
    top: 10px;
}
.timeline-date{
    font-size: 20px;
    font-weight: 800;
    color: white;
    margin: 6px 0 15px;
}
.timeline-content{
    padding: 2rem;
    background-color: #090808;
    border: 3px solid #47feec;
    border-radius: 4rem;
    box-shadow: 0 0 10px #47feec;
    cursor: pointer;
    transition: 0.3s ease;
}
.timeline-content:hover{
    transform: scale(1.05);
    box-shadow: 0 0 25px #47feec
}
/* .timeline-content h1{
    font-size: 20px;
    color: white;
    margin: 0 0 10px;
    font-weight: 500;
} */
/* .timeline-content p{
    color: white;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
} */
.achivements-content{
    opacity: 0;
    transition: 0.25s ease-in-out;
    padding: 10px;
    border-radius: 5px;
}

.achivements-content-m {
    opacity: 0;
    transition: 0.25s ease-in-out;
    padding: 10px;
    border-radius: 5px;
}
.achivements-content.show{
    opacity: 1;
    animation: 0.5s splash linear 1 forwards;
}

.mobile-time-line .content-line-item  {
    padding: 2rem;
    background-color: #090808;
    border: 3px solid #47feec;
    border-radius: 3rem;
    box-shadow: 0 0 10px #47feec;
    cursor: pointer;
    transition: 0.3s ease;
}

.mobile-time-line .dot {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-55%, 0%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #47feec;
}

.mobile-time-line .date {
    position: absolute;
    left: 30px;
    top: 0px;
    font-size: 20px;
    font-weight: 800;
}