@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.light{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    background: radial-gradient(circle at var(--x) var(--y), transparent 10%, rgba(0,0,0,0.95) 70%);
}
.light.full{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    background: radial-gradient(circle at var(--x) var(--y), transparent 10%, rgba(0,0,0,0.95) 70%);
    animation: showLight 1s linear 1 forwards;
}
@keyframes showLight {
    to{
        opacity: 0;
    }
}
.flash{
    opacity: 0.5;
    margin-right: 2vw;
}
.flash.on{
    opacity: 1;
    margin-right: 2vw;
    animation: off 1s linear 1 forwards;
}
/* .hidden-navBar{
    transform: translateY(-77px);
    transition: 0.25s ease transform;
    transition-delay: 2s;
} */
.spacer{
    background-image: linear-gradient(
        to bottom, #87323200, #000
    );
}
.second-name{
    text-shadow: 0px 0px 20px #47feec;
}
.nav-bar-button{
    color: white;
    font-weight: 500;
    transition: 0.3s ease;
    border-bottom: 3px solid transparent;
}
.nav-bar-button:hover,
.nav-bar-button.selected{
    color: #47feec;
    border-bottom: 3px solid #47feec;
}
body{
    scroll-behavior: smooth;
}
.contact-box{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 2vh;
    z-index: 100;
    cursor: pointer;
}
.contact-box .icon-wrapper{
    position: relative;
    border-width: 2px;
    padding: 5px; 
    border-color: #47feec;
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: 50%;
    color: #47feec;
    z-index: 100;
    transition: 0.3s ease-in-out;
}
.contact-box .icon-wrapper-m{
    position: relative;
    border-width: 2px;
    padding: 5px; 
    border-color: #47feec;
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: 50%;
    color: #47feec;
    z-index: 100;
    transition: 0.3s ease-in-out;
}
.contact-box .icon-wrapper:hover{
    position: relative;
    border-width: 2px;
    padding: 5px; 
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: 50%;
    color: #fff;
    z-index: 100;
    background-color: #47feec;
    box-shadow: 0 0 15px #47feec;
}
.btn-group .btn{
    display: inline-block;
    padding: 0.6rem 1.9rem;
    background-color: #47feec;
    box-shadow: 0 0 25px #47feec;
    border-radius: 3rem;
    font-size: 1rem;
    color: #000;
    border: 2px solid transparent;
    letter-spacing: 0.1rem;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.btn-group .btn-m{
    display: inline-block;
    padding: 0.6rem 1.9rem;
    background-color: #47feec;
    box-shadow: 0 0 25px #47feec;
    border-radius: 3rem;
    font-size: 1rem;
    color: #000;
    border: 2px solid transparent;
    letter-spacing: 0.1rem;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.btn:hover{
    transform: scale(1.05);
    box-shadow: 0 0 50px #47feec;
}
.btn-group{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 4vh;
}
.btn-group div:nth-of-type(2){
    background-color: #000;
    color: #47feec;
    border: 2px solid #47feec;
    box-shadow: 0 0 25px transparent;
}
.btn-group div:nth-of-type(2):hover{
    box-shadow: 0 0 25px #47feec;
    background-color: #47feec;
    color: #000;
}
.projects-underline{
    background: #47feec;;
    box-shadow: 0 0 15px #47feec;
}
.drag-me{
    opacity: 0;
    display: none;
    text-shadow: 0 0 25px #47feec;
    animation: pulsar 3s linear infinite;
}
@keyframes pulsar{
    0%{
        opacity: 0.2;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0.2;
    }
}
.drag-me.show{
    opacity: 1;
    display: block;
}
.input-base-wrapper-m{
    width: 100%;
    height: 8vh;
    border: solid #47feec;
    border-width: 2px;
    border-radius: 1rem;
    padding: 1rem 1rem;
}
.input-base{
    display: flex;
    background-color: transparent;
    align-items: start;
    width: 100%;
    height: 100%;
    outline: none;
}
.input-base-wrapper{
    width: 30vw;
    height: 7vh;
    border: solid #47feec;
    border-width: 2px;
    border-radius: 1rem;
    padding: 1rem 1rem;
}
.input-main-wrapper-m{
    width: 90vw;
    border: solid #47feec;
    border-width: 2px;
    border-radius: 1rem;
    padding: 1rem;
}
.input-main-wrapper{
    width: 30vw;
    height: 30vh;
    border: solid #47feec;
    border-width: 2px;
    border-radius: 1rem;
    padding: 1rem;
}
.input-main{
    display: flex;
    background-color: transparent;
    border: #47feec;
    border-width: 5px;
    align-items: start;
    width: 80vw;
    height: 100%;
    outline: none;
}
.input-main::-webkit-scrollbar{
    display: none;
}
.submit-btn{
    cursor: pointer;
    color: #000;
    background-color: #47feec;
    padding: 0.8rem 2.5rem;
    border-radius: 2rem;
    box-shadow: 0 0 15px#47feec;
    transition: all 0.3s ease-in-out;
    font-weight: bold;
}
.submit-btn:hover{
    color: #fff;
    box-shadow: 0 0 25px#47feec;
}
.achivements{
    width: 100%;
    display: flex;
    justify-content: center;
}
.content-neon{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    scroll-behavior: smooth;
    gap: 5vw;
}
.content-neon::-webkit-scrollbar{
    display: none;
}
.parent-neon{
    height: auto;
    width: 26vw;
    max-width: 450px;
    min-width: 350px;
    padding: 20px;
    perspective: 1000px;
}
.card{
    filter: hue-rotate(calc(40deg*var(--p)));
    padding-top: 10vh;
    transform-style: preserve-3d;
    background-position: center center;
    width: 100%;
    height: auto;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgba(0, 115, 255, 1) 0px 20px 60px -25px;
}
.card:hover{
    background-position: 80% 20%;
    transform: rotate3d(0.5, 1, 0, 15deg);
}
.date-box{
    position: absolute;
    top: 75px;
    left: 25px;
    height: 60px;
    width: 90px;
    background-color: #000;
    border-radius: 10px;
    padding: 10px;
    box-shadow: rgb(0, 102, 255) 0px 20px 60px 0px, rgb(0, 200, 255) 0px 18px 36px -18px;
    transform: translate3d(0, 0, 150px);
}
.date-box{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.date-box .month{
    color: white;
    font-size: 11px;
    font-weight: 700;
}
.date-box .date{
    font-size: 20px;
    font-weight: 900;
    color: rgb(0, 136, 255);
}
.see-more{
    color: rgb(0, 136, 255);
    font-size: 1.25rem;
    font-weight: 900;
    text-shadow: rgb(0, 136, 255) 0px 0px 30px;
    transition: all 0.3s ease-in-out;
}
.see-more:hover{
    color: #fff;
    text-shadow: #fff 0px 0px 30px;

}

.main-text-name{
    text-shadow: #47feec;
    font-size: 3rem;
    font-weight: 900;
    color: #47feec;
}
.swiper {
    width: 90vw;
    height: 70%;
}
.swiper {
    width: 75vw;
    height: 55vh;
}
  
.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    background: #47feec;
    box-shadow: #000 0px 0px 30px 10px;
}
.swiper-slide-content{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
}

