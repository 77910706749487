.drop-down-wrapper {
  position: relative;
}

.drop-down-wrapper .drop-down {
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #161717;
  border-radius: 5px;
  border: 1px solid #47feec;
  display: inline-block;
  white-space: nowrap;
}

.drop-down-wrapper .drop-down .item-selected {
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.down-elements {
  height: 0px;
  width: 100%;
  /* transition: 0.5s; */
  display: flex;
  overflow: hidden;
}

.down-item {
  width: 100%;
  height: 50px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.down-item:nth-child(1) {
  border-bottom: 1px solid white;
}

.down-elements.open {
  width: 100%;
  height: 100px;
  background-color: #000000;
  border-radius: 5px;
  border: 1px solid #47feec;
  margin-top: 5px;
  display: inline-block;
}
