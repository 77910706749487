.resume-wrapper {
  min-width: 1024px;
  min-height: 1800px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
}

.resume-wrapper .resume {
  width: 1024px;
  height: 1800px;
  background-color: #ededed3d;
  display: flex;
  /* box-shadow: 0px 0px 200px 80px  #47feec20; */
}

.left-side-menu {
  width: 30%;
  height: 100%;
  background-color: #383c4c;
  padding-top: 10px;
}

.content-about {
  width: 70%;
  height: 100%;
  padding-top: 10px;
  background-color: #dfdfdf;
  display: flex;
  justify-content: start;
  flex-direction: column;
}

.me-data {
  width: 100%;
}

.item-box:nth-child(1) {
  padding-bottom: 50px;
}