body{
    color: white;
}
.content{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    overflow: hidden;
}
.slider{
    width: 65vw;
    height: 65vw;
    transform: rotate(calc(var(--rotate) * 1deg));
    transition: 2s;
}
.slider .item{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(calc(40deg * var(--i)));
    pointer-events: none;
}
.slider .item::before{
    overflow: hidden;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 22vw;
    height: 27vh;
    border-radius: 20px;
    transition: 2s;
    transform: rotate(calc((-40deg * var(--i) - (var(--rotate) * 1deg))));
}
.item-content{
    position: absolute;
    border-radius: 20px;
    width: 22vw;
    height: 27vh;
    background: green;
    transition: 2s;
    transform: rotate(calc((-40deg * var(--i) - (var(--rotate) * 1deg))));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    top: 0;
    left: 0;
    z-index: 300;
}
.item-content.main{
    position: absolute;
    border-radius: 20px;
    width: 22vw;
    height: 27vh;
    background: green;
    transition: 2s;
    transform: rotate(calc((-40deg * var(--i) - (var(--rotate) * 1deg))));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scale: 1;
    animation: focus 2s linear 1 forwards;
    border-width: 0px;
}
@keyframes focus {
    to{
        border-width: 2px;
        border-color: aqua;
    }
}
.buttons.hide{
    opacity: 1;
    scale: 0;
    transition: 0.5s;
}
.buttons{
    opacity: 1;
    scale: 1;
    transition: 0.5s;
}
.project-preView-content{
    cursor: pointer;
    position: relative;
    margin-top: 20vh;
    width: 50vw;
    height: 50vh;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    animation: reStartContent 1s ease-in-out 1 forwards;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}
@keyframes reStartContent {
    0%{
        opacity: 1;
    }
    20%{
        opacity: 0;
    }
    30%{
        opacity: 0;
    }
    100%{
        opacity: 1;;
    }
}
.glass-title{
    text-shadow: 0 0 5px #47feec;
}
