@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

 
@layer components {
    .page_format {
        @apply w-screen  h-screen flex flex-col overflow-x-hidden items-center bg-bg_primary z-40
    }
    .author_page_main_container {
        @apply w-full h-full flex flex-col items-center justify-center cursor-default  py-[8vh]
    }
    .home_page {
        @apply bg-bg_primary w-full min-h-screen flex flex-col items-center max-w-[100vw] overflow-x-hidden
    }
    .nav_bar {
        @apply fixed h-auto top-0 w-full flex flex-row cursor-pointer bg-bg_primary bg-opacity-50 backdrop-blur-xl  z-[103] justify-center 
    }
    .nav_bar_wrapper {
        @apply w-[90%] h-[80px] z-50 flex flex-row items-center justify-between
    }
    .main_page_layout {
        @apply w-full overflow-hidden h-full bg-primary max-w-[100vw] overflow-x-hidden
    }
    .nav_bar_buttons_container{
        @apply w-full flex flex-row z-50 mt-2 justify-between items-center
    }
    .text {
        @apply font-main 
    }    
    .text_prime {
        @apply font-main text-text_primary
    }
    .button_more {
        @apply text-bg_primary bg-text_accented h-[50px] w-[250px] rounded-3xl
    }
    .input_home_page {
        @apply bg-primary  h-[60px] px-8 py-4 rounded-2xl outline-none 
    }
}